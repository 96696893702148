import React from "react";
import "./tatorey.scss";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import HydrotreatingSwiper from "../hydrotreating/hydrotreatingSwiper";
import ModalComponent from "../ModalComponent/ModalComponent";

function tatorey() {
  return (
    <div className="tatorey">
      <div className="content">
        <h1>Таторей</h1>

        <Accordion allowToggle width="100%">
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: "#52527a;", color: "white" }}>
                Теоретический материал
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <div className="tatorey__modal">
                <h3>НАЗНАЧЕНИЕ УСТАНОВКИ</h3>
                <p>
                  “ТАТОРЕЙ”– каталитический процесс трансалкилирования
                  ароматических углеводородов. Технологическая схема блока
                  «Таторей» однопоточная и состоит из следующих отделений:
                </p>
                <ul>
                  <li>реакторное отделение;</li>
                  <li>отделение стабилизации катализата;</li>
                  <li>отделение фракционирования катализата.</li>
                </ul>
                <p>
                  Процесс «Таторей» предназначен для увеличения производства
                  бензола и ароматических углеводородов С₈ из толуола и
                  ароматических углеводородов С₉ путем реакции
                  трансалкилирования в присутствии водородсодержащего газа на
                  катализаторе ТА-5.
                </p>
                <h3>СЫРЬЁ, ГОТОВАЯ ПРОДУКЦИЯ</h3>
                <p>
                  Сырьём является толуол, ароматические углеводороды С₉.
                  Производимой продукцией являются бензол и ароматические
                  углеводороды С₈.
                </p>
                <h3>Технологические параметры процесса Таторей.</h3>
                <h4>ТЕМПЕРАТУРА</h4>
                <p>
                  В промышленной эксплуатации температура реакции является
                  главным параметром, используемым для регулирования
                  конверсии.При нормальных условиях температура регулируется
                  таким образом, чтобы поддержать необходимый уровень конверсии,
                  который обычно составляет 45-50 % масс. за один проход.
                </p>
                <h4>ДАВЛЕНИЕ И РАСХОД РЕЦИРКУЛИРУЮЩЕГО ГАЗА</h4>
                <span>
                  Для проведения реакций требуется присутствие водорода в
                  реакционной зоне.
                </span>
                <p>Ниже описывается влияние водорода на процесс.</p>
                <span>
                  Для предотвращения чрезмерного отложения кокса на катализаторе
                  расход водородсодержащего газа должен поддерживаться на уровне
                  или выше проектного соотношения водород-углеводороды в сырье,
                  подаваемом в реактор. Более высокие значения, которые не
                  превышают параметров оборудования, не приносят ущерба.
                  Пониженный расход рециркулирующего газа приводит к увеличению
                  отложения кокса на катализаторе.
                </span>
                <h4>МАССОВАЯ СКОРОСТЬ ПОДАЧИ СЫРЬЯ</h4>
                <p>
                  Этот термин означает массу комбинированного сырья в жидкой
                  фазе, загружаемой в реактор в час, и служит показателем
                  жесткости режима работы.Чем меньше массовая скорость подачи
                  сырья, тем большее количество катализатора на единицу сырья и
                  (при прочих одинаковых параметрах) выше жесткость режима.
                </p>
                <p>
                  Минимальная производительность процесса Таторей обычно
                  составляет 50 % от проектной массовой скорости подачи сырья.
                </p>
                <h4>СЫРЬЕ</h4>
                <p>
                  Селективность катализатора характеризуется относительным
                  выходом бензола и ароматики С₈ в зависимости от состава
                  перерабатываемого сырья. Как было упомянуто ранее, постепенное
                  снижение селективности происходит при повышении жесткости
                  рабочего режима. Это вызвано усилением нежелательных побочных
                  реакций. Раскрытие колец ароматики С6 – С₉, происходящее в
                  реакторе Таторея, является четким показателем селективности
                  катализатора. Сохранение колец должно быть выше 98,5 % при
                  использовании сырья, содержащего только толуол.
                </p>
                <p>
                  Другим и, возможно, более точным средством контроля
                  селективности катализатора является расчет выхода балансовых
                  предельных соединений за вычетом любых легких фракций,
                  образующихся при деалкилировании тяжелых алкильных групп.
                  Правильный материальный баланс установки является обязательным
                  условием для точного определения селективности катализатора.
                </p>
                <h4>КАТАЛИЗАТОР И ЯДЫ КАТАЛИЗАТОРА</h4>
                <p>
                  Состав, приготовление и активирование катализатора
                  обеспечивают его оптимальную активность при трансалкилировании
                  ароматики на протяжении всего рабочего цикла. Сам катализатор
                  не является производственным параметром, но определенные
                  соединения в сырье могут отравить или дезактивировать
                  катализатор, что приведет к снижению скорости реакции и
                  эффективности работы установки. Исходя из приведенных выше
                  факторов, обычно рекомендуется использовать на установке
                  .Таторей экстрагированное ароматическое сырье или, как
                  минимум, сырье с очень низкой концентрацией предельных
                  углеводородов.
                </p>
                <h4>Описание технологической схемы.</h4>
                <p>
                  Реакторное отделение состоит из узла подогрева газосырьевой
                  смеси и реакторного узла.
                </p>
                <p>
                  Сырьё – толуол с установки «ЛГ-35/8-300Б» и ароматика С₉+ с
                  526 резервуара с установки «Бензол» двумя независимыми
                  технологическими потоками поступают на установку.
                </p>
                <p>
                  Расход толуола на установку регистрируется прибором
                  <strong>FQJR-474</strong>. Температура толуола на установку
                  регистрируется прибором <strong>TJR-17/57</strong>. Расход
                  ароматики С₉ на установку регулируется прибором
                  <strong>FQJRC-475</strong>, клапан-регулятор которого
                  установлен на трубопроводе ароматики С₉ перед тройником
                  смешения с толуолом. Температура ароматики С₉ регистрируется
                  прибором
                  <strong>TJR-17/59</strong>.
                </p>
                <p>
                  Комбинированное сырье (толуол и ароматика С9) поступает в
                  межтрубное пространство теплообменников Т-19 и Х-20
                  соответственно. В теплообменниках Т-19, Х-20 сырье
                  подогревается потоком ксилольной фракции от ЦН-29,30 и
                  поступает в сырьевые емкости К-2, К-3. Температура сырья на
                  выходе из межтрубного пространства теплообменника Х-20
                  регистрируется прибором
                  <strong>TJR-16/47</strong>. Давление в сырьевых емкостях К-2,
                  К-3 поддерживается путем подачи водородсодержащего газа из
                  линии обмена ВСГ или водородсодержащим газом с установки
                  «Бензол» и сдувки избытка ВСГ на факел. Давление в сырьевой
                  емкости К-2 регулируется прибором <strong>PJRCA-453</strong>c
                  сигнализацией по максимальному значению
                  клапанами-регуляторами, установленными на трубопроводах подачи
                  ВСГ в К-2 и сдувки ВСГ из К-2 в трубопровод факела установки.
                  Давление в сырьевой емкости К-3 регулируется прибором{" "}
                  <strong>PJRCA-441</strong> c сигнализацией по максимальному
                  значению клапанами-регуляторами, установленными на
                  трубопроводах подачи ВСГ в К-3 и сдувки ВСГ из К-3 в
                  трубопровод факела установки.
                </p>
                <p>
                  Уровень сырья в сырьевой емкости К-2 регистрируется прибором
                  <strong>LJRA-501А</strong> с сигнализацией по минимальному
                  значению и прибором <strong>LJRСA-501</strong> с сигнализацией
                  по максимальному значению. С выкидного трубопровода насосов
                  ЦН-2,3 сырьё подается в тройник смешения на смешение с
                  водородсодержащим газом, поступающим с выкидного трубопровода
                  компрессоров ПК-2,3,4. Расход сырья в тройник смешения с ВСГ
                  регулируется прибором
                  <strong>FQJRCA-461</strong>, клапан-регулятор которого
                  установлен на выкидном трубопроводе насосов ЦН-2, ЦН-3 с
                  сигнализацией минимального значения. Расход сырья в тройник
                  смешения с ВСГ регистрируется прибором{" "}
                  <strong>FRSA-461А</strong> с сигнализацией (при 10 м3/ч) и
                  блокировкой (при 5 м3/ч) по минимальному значению. Расход
                  водородсодержащего газа в тройник смешения регистрируется
                  прибором <strong>FRSA-462А</strong> с сигнализацией (при 15000
                  Нм3/ч) и блокировкой (при 10000 Нм3/ч) по минимальному
                  значению.
                </p>
                <p>
                  Из тройника смешения смесь сырья и ВСГ (ГСС-газосырьевая
                  смесь) поступает в межтрубное пространство сырьевых
                  теплообменников Т-2/II, Т-3/II, Т-4/II, где нагревается за
                  счет температуры газопродуктовой смеси (ГПС), выходящей из
                  реакторов Р-1/II, Р-2/II, Р-3/II, поступающей последовательно
                  в трубное пространство теплообменников Т-4/II, Т-3/II, Т-2/II.
                </p>
                <p>
                  Для поддержания температуры ГПС на выходе из Т-4/II не выше
                  325 оС, часть ГСС байпасируется мимо Т-4/II через клапан
                  регулятор TRC-403. После сырьевых теплообменников ГСС
                  направляется в секцию II печи П-1.
                </p>
                <p>
                  Ограничение температуры ГПС не выше 325 °С связано с рабочей
                  температурой рибойлера Т-5 отпарной колонны К-4. Температура
                  ГСС на выходе из межтрубного пространства сырьевого
                  теплообменника Т-2/II регистрируется прибором{" "}
                  <strong>TJR-16/05</strong>. Температура ГСС на выходе из
                  межтрубного пространства сырьевого теплообменника Т-3/II
                  регистрируется прибором
                  <strong>TJR-16/02</strong>. Температура ГСС на выходе из
                  межтрубного пространства сырьевого теплообменника Т-4/II
                  регистрируется прибором <strong>TJR-16/04</strong>.
                </p>
                <p>
                  Далее ГСС подогревается в двухпоточном трубном змеевике
                  радиантной камеры II печи П-1, откуда с температурой до 490 °С
                  поступает в последовательно расположенные реакторы блока
                  «Таторей» Р-1/II, Р-2/II, Р-3/II.
                </p>
                <p>
                  Температура на выходе из первого и второго потока змеевика
                  камеры II печи П-1 регистрируется прибором{" "}
                  <strong>TJRА-16/21</strong> и<strong>TJRА-16/22</strong>{" "}
                  соответственно, с сигнализацией по максимальному значению.
                  Температура по перевалам камеры II печи П-1 регистрируется
                  приборами <strong>TJRА-15/54</strong> (низ камеры),{" "}
                  <strong>TJRА-15/55</strong> (середина камеры),
                  <strong>TJRА-15/56</strong>
                  (верх камеры) с сигнализацией по максимальному значению.
                  Температура ГСС на входе в реактор Р-1/II регулируется
                  прибором TRC-404, клапан-регулятор которого установлен на
                  трубопроводе топливного газа к форсункам радиантной камеры II
                  печи П-1 и контролируется прибором TJRА-16/26 c сигнализацией
                  по максимальному значению.
                </p>
                <p>
                  В реакторах Р-1/II, Р-2/II, Р-3/II с аксиальным вводом сырья
                  на алюмосиликатном катализаторе ТА-5 в среде
                  водородсодержащего газа происходит реакция трансалкилирования
                  толуола и ароматики С9 в ароматические углеводороды С8.
                  Количество катализатора ТА-5, загруженного в реакторы Р-1/II,
                  Р-2/II, Р-3/II составляет 12760 кг в соотношении по реакторам
                  1:1:1. Температура стенок реакторов Р-1/II, Р-2/II, Р-3/II
                  регистрируется приборами
                  <strong>
                    TJRА-15/2÷9, 11÷16, 19,21÷29, 31÷34, 36÷39, 41÷47, 51÷53
                  </strong>
                  с сигнализацией по максимальному значению.
                </p>
                <p>
                  Температура ГПС на входе в реактор Р-2/II регистрируется
                  прибором
                  <strong>TJRА-16/23</strong> с сигнализацией по максимальному
                  значению. Температура ГПС на входе в реактор Р-3/II
                  регистрируется прибором <strong>TJRА-16/24</strong> с
                  сигнализацией по максимальному значению.
                </p>
                <p>
                  Давление ГСС на входе в реактор Р-1/II регистрируется прибором
                  <strong>PJR-420</strong>. Перепад давления по реактору Р-1/II
                  регистрируется прибором <strong>PDJRA-422/1</strong> c
                  сигнализацией по максимальному значению.
                </p>
                <p>
                  Давление ГПС на входе в реактор Р-2/II регистрируется прибором
                  <strong>PJR-421</strong>. Перепад давления по реактору Р-2/II
                  регистрируется прибором <strong>PDJRA-422/2</strong> c
                  сигнализацией по максимальному значению. Давление ГПС на входе
                  в реактор Р-3/II регистрируется прибором{" "}
                  <strong>PJR-422</strong>. Перепад давления по реактору Р-3/II
                  регистрируется прибором
                  <strong>PDJRA-422/3</strong> c сигнализацией по максимальному
                  значению. Давление ГПС на выходе из реактора Р-3/II
                  регистрируется прибором <strong>PJR-423</strong>.
                </p>
                <p>
                  Из реактора Р-3/II ГПС поступает последовательно в трубное
                  пространство теплообменника Т-4/II, рибойлера Т-5,
                  теплообменников Т-3/II, Т-2/II, Температура ГПС на выходе из
                  реактора Р-3/II регистрируется прибором{" "}
                  <strong>TJRА-16/25</strong> с сигнализацией по максимальному
                  значению.
                </p>
                <p>
                  Для поддержания температурного режима в стабилизационной
                  колонне К-4, часть ГПС из теплообменника Т-4/II направляется в
                  трубное пространство рибойлера Т-5, далее поступает в трубное
                  пространство теплообменника Т-3/II и далее по описанной выше
                  схеме. В теплообменнике Т-4/II происходит охлаждение ГПС. В
                  рибойлере Т-5 часть ГПС охлаждается и после смешения с
                  байпасным потоком ГПС поступает в трубное пространство
                  теплообменника Т-3/II. Температура ГПС на выходе из Т-5
                  регистрируется прибором
                  <strong>TJR-17/02</strong>. В теплообменнике Т-3/II происходит
                  охлаждение ГПС, температура ГПС из теплообменника Т-3/II
                  регистрируется прибором <strong>TJR-16/03</strong>. В
                  теплообменнике Т-2/II происходит охлаждение ГПС.
                </p>
                <p>
                  Температура ГПС на входе в трубное пространство рибойлера Т-5
                  регулируется прибором TRС-403, клапан-регулятор которого
                  расположен на трубопроводе байпаса ГСС помимо теплообменника
                  Т-4/II, и регистрируется прибором <strong>TJRА-16/01</strong>.
                  Температура на выходе ГПС из трубного пространства рибойлера
                  Т-5 регистрируется прибором <strong>TJR-17/02</strong>.
                  Температура на выходе ГПС из Т-2/II регистрируется прибором
                  <strong>TJR-16/06</strong>.
                </p>
                <p>
                  Далее ГПС поступает в трубное пространство Т-1/II, где
                  охлаждается, нагревая нестабильный катализат из С-7.
                </p>
                <p>
                  ГПС из трубного пространства теплообменника Т-1/II поступает в
                  воздушный холодильник ВХК-1/II, в межтрубное пространство
                  водяного холодильника Х-1/II, из которого поступает в
                  сепаратор С-7.
                </p>
                <p>
                  Температура ГПС на выходе из Т-1/II регистрируется прибором
                  <strong>TJR-16/07</strong>. Температура ГПС на выходе из
                  ВХК-1/II регистрируется прибором <strong>TJR-16/08</strong>.
                  Для регулирования мольного соотношения «водород-углеводороды»
                  технологической схемой предусмотрен трубопровод с выкида
                  компрессоров ПК-2, ПК-3, ПК-4 на вход в воздушный холодильник
                  ВХК-1/II. Количество ВСГ, байпасируемого помимо реакторного
                  блока, регистрируется прибором <strong>FJR-480</strong>.
                </p>
                <p>
                  Температура ГПС на выходе из водяного холодильника Х-1/II
                  регулируется прибором <strong>TRC-402</strong>,
                  клапан-регулятор которого расположен на трубопроводе обратной
                  охлаждающей воды из трубного пространства водяного
                  холодильника Х-1/II. Технологической схемой предусмотрено
                  отключение запорной арматурой водяного холодильника Х-1/II по
                  продукту в случае пропуска трубного пучка. Температура ГПС на
                  выходе из водяного холодильника Х-1/II также регистрируется
                  прибором <strong>TJR-16/09</strong>. Технологической схемой
                  предусмотрено отключение воздушного холодильника ВХК-1/II в
                  случае пропуска трубных секций.
                </p>
                <p>
                  Давление ГПС на входе в реактор Р-2/II регистрируется прибором
                  <strong>PJR-421</strong>. Перепад давления по реактору Р-2/II
                  регистрируется прибором <strong>PDJRA-422/2</strong> c
                  сигнализацией по максимальному значению. Давление ГПС на входе
                  в реактор Р-3/II регистрируется прибором{" "}
                  <strong>PJR-422</strong>. Перепад давления по реактору Р-3/II
                  регистрируется прибором
                  <strong>PDJRA-422/3</strong> c сигнализацией по максимальному
                  значению. Давление ГПС на выходе из реактора Р-3/II
                  регистрируется прибором <strong>PJR-423</strong>.
                </p>
                <p>
                  Из реактора Р-3/II ГПС поступает последовательно в трубное
                  пространство теплообменника Т-4/II, рибойлера Т-5,
                  теплообменников Т-3/II, Т-2/II, Температура ГПС на выходе из
                  реактора Р-3/II регистрируется прибором{" "}
                  <strong>TJRА-16/25</strong> с сигнализацией по максимальному
                  значению.
                </p>
                <p>
                  Для поддержания температурного режима в стабилизационной
                  колонне К-4, часть ГПС из теплообменника Т-4/II направляется в
                  трубное пространство рибойлера Т-5, далее поступает в трубное
                  пространство теплообменника Т-3/II и далее по описанной выше
                  схеме. В теплообменнике Т-4/II происходит охлаждение ГПС. В
                  рибойлере Т-5 часть ГПС охлаждается и после смешения с
                  байпасным потоком ГПС поступает в трубное пространство
                  теплообменника Т-3/II. Температура ГПС на выходе из Т-5
                  регистрируется прибором
                  <strong>TJR-17/02</strong>. В теплообменнике Т-3/II происходит
                  охлаждение ГПС, температура ГПС из теплообменника Т-3/II
                  регистрируется прибором <strong>TJR-16/03</strong>. В
                  теплообменнике Т-2/II происходит охлаждение ГПС.
                </p>
                <p>
                  Температура ГПС на входе в трубное пространство рибойлера Т-5
                  регулируется прибором TRС-403, клапан-регулятор которого
                  расположен на трубопроводе байпаса ГСС помимо теплообменника
                  Т-4/II, и регистрируется прибором <strong>TJRА-16/01</strong>.
                  Температура на выходе ГПС из трубного пространства рибойлера
                  Т-5 регистрируется прибором <strong>TJR-17/02</strong>.
                  Температура на выходе ГПС из Т-2/II регистрируется прибором
                  <strong>TJR-16/06</strong>.
                </p>
                <p>
                  Далее ГПС поступает в трубное пространство Т-1/II, где
                  охлаждается, нагревая нестабильный катализат из С-7.
                </p>
                <p>
                  ГПС из трубного пространства теплообменника Т-1/II поступает в
                  воздушный холодильник ВХК-1/II, в межтрубное пространство
                  водяного холодильника Х-1/II, из которого поступает в
                  сепаратор С-7.
                </p>
                <p>
                  Температура ГПС на выходе из Т-1/II регистрируется прибором
                  <strong>TJR-16/07</strong>. Температура ГПС на выходе из
                  ВХК-1/II регистрируется прибором <strong>TJR-16/08</strong>.
                  Для регулирования мольного соотношения «водород-углеводороды»
                  технологической схемой предусмотрен трубопровод с выкида
                  компрессоров ПК-2, ПК-3, ПК-4 на вход в воздушный холодильник
                  ВХК-1/II. Количество ВСГ, байпасируемого помимо реакторного
                  блока, регистрируется прибором <strong>FJR-480</strong>.
                </p>
                <p>
                  Температура ГПС на выходе из водяного холодильника Х-1/II
                  регулируется прибором <strong>TRC-402</strong>,
                  клапан-регулятор которого расположен на трубопроводе обратной
                  охлаждающей воды из трубного пространства водяного
                  холодильника Х-1/II. Технологической схемой предусмотрено
                  отключение запорной арматурой водяного холодильника Х-1/II по
                  продукту в случае пропуска трубного пучка. Температура ГПС на
                  выходе из водяного холодильника Х-1/II также регистрируется
                  прибором <strong>TJR-16/09</strong>. Технологической схемой
                  предусмотрено отключение воздушного холодильника ВХК-1/II в
                  случае пропуска трубных секций.
                </p>
                <p>
                  В сепараторе С-7 происходит отделение нестабильного катализата
                  (БТКФ) от циркулирующего водородсодержащего газа.
                  Циркуляционный ВСГ поступает в приемный сепаратор С-4 и далее
                  на прием циркуляционных компрессоров ПК-2,3,4, откуда ВСГ,
                  после подпитки свежим ВСГ, поступает в тройник смешения.
                  Концентрация водорода в ВСГ, поступающего с нагнетания
                  циркуляционных компрессоров, регистрируется прибором{" "}
                  <strong>QJRA-520</strong>. Давление ВСГ на нагнетании
                  циркуляционных компрессоров регистрируется прибором
                  <strong>PJR-444</strong>. Температура ВСГ в сепараторе С-7
                  регистрируется прибором <strong>TJR-16/11</strong>.
                </p>
                <p>
                  Давление ГПС на входе в реактор Р-2/II регистрируется прибором
                  <strong>PJR-421</strong>. Перепад давления по реактору Р-2/II
                  регистрируется прибором <strong>PDJRA-422/2</strong> c
                  сигнализацией по максимальному значению. Давление ГПС на входе
                  в реактор Р-3/II регистрируется прибором{" "}
                  <strong>PJR-422</strong>. Перепад давления по реактору Р-3/II
                  регистрируется прибором
                  <strong>PDJRA-422/3</strong> c сигнализацией по максимальному
                  значению. Давление ГПС на выходе из реактора Р-3/II
                  регистрируется прибором <strong>PJR-423</strong>.
                </p>
                <p>
                  Из реактора Р-3/II ГПС поступает последовательно в трубное
                  пространство теплообменника Т-4/II, рибойлера Т-5,
                  теплообменников Т-3/II, Т-2/II, Температура ГПС на выходе из
                  реактора Р-3/II регистрируется прибором{" "}
                  <strong>TJRА-16/25</strong> с сигнализацией по максимальному
                  значению.
                </p>
                <p>
                  Для поддержания температурного режима в стабилизационной
                  колонне К-4, часть ГПС из теплообменника Т-4/II направляется в
                  трубное пространство рибойлера Т-5, далее поступает в трубное
                  пространство теплообменника Т-3/II и далее по описанной выше
                  схеме. В теплообменнике Т-4/II происходит охлаждение ГПС. В
                  рибойлере Т-5 часть ГПС охлаждается и после смешения с
                  байпасным потоком ГПС поступает в трубное пространство
                  теплообменника Т-3/II. Температура ГПС на выходе из Т-5
                  регистрируется прибором
                  <strong>TJR-17/02</strong>. В теплообменнике Т-3/II происходит
                  охлаждение ГПС, температура ГПС из теплообменника Т-3/II
                  регистрируется прибором <strong>TJR-16/03</strong>. В
                  теплообменнике Т-2/II происходит охлаждение ГПС.
                </p>
                <p>
                  Температура ГПС на входе в трубное пространство рибойлера Т-5
                  регулируется прибором TRС-403, клапан-регулятор которого
                  расположен на трубопроводе байпаса ГСС помимо теплообменника
                  Т-4/II, и регистрируется прибором <strong>TJRА-16/01</strong>.
                  Температура на выходе ГПС из трубного пространства рибойлера
                  Т-5 регистрируется прибором <strong>TJR-17/02</strong>.
                  Температура на выходе ГПС из Т-2/II регистрируется прибором
                  <strong>TJR-16/06</strong>.
                </p>
                <p>
                  Далее ГПС поступает в трубное пространство Т-1/II, где
                  охлаждается, нагревая нестабильный катализат из С-7.
                </p>
                <p>
                  ГПС из трубного пространства теплообменника Т-1/II поступает в
                  воздушный холодильник ВХК-1/II, в межтрубное пространство
                  водяного холодильника Х-1/II, из которого поступает в
                  сепаратор С-7.
                </p>
                <p>
                  Температура ГПС на выходе из Т-1/II регистрируется прибором
                  <strong>TJR-16/07</strong>. Температура ГПС на выходе из
                  ВХК-1/II регистрируется прибором <strong>TJR-16/08</strong>.
                  Для регулирования мольного соотношения «водород-углеводороды»
                  технологической схемой предусмотрен трубопровод с выкида
                  компрессоров ПК-2, ПК-3, ПК-4 на вход в воздушный холодильник
                  ВХК-1/II. Количество ВСГ, байпасируемого помимо реакторного
                  блока, регистрируется прибором <strong>FJR-480</strong>.
                </p>
                <p>
                  Температура ГПС на выходе из водяного холодильника Х-1/II
                  регулируется прибором <strong>TRC-402</strong>,
                  клапан-регулятор которого расположен на трубопроводе обратной
                  охлаждающей воды из трубного пространства водяного
                  холодильника Х-1/II. Технологической схемой предусмотрено
                  отключение запорной арматурой водяного холодильника Х-1/II по
                  продукту в случае пропуска трубного пучка. Температура ГПС на
                  выходе из водяного холодильника Х-1/II также регистрируется
                  прибором <strong>TJR-16/09</strong>. Технологической схемой
                  предусмотрено отключение воздушного холодильника ВХК-1/II в
                  случае пропуска трубных секций.
                </p>
                <p>
                  В сепараторе С-7 происходит отделение нестабильного катализата
                  (БТКФ) от циркулирующего водородсодержащего газа.
                  Циркуляционный ВСГ поступает в приемный сепаратор С-4 и далее
                  на прием циркуляционных компрессоров ПК-2,3,4, откуда ВСГ,
                  после подпитки свежим ВСГ, поступает в тройник смешения.
                  Концентрация водорода в ВСГ, поступающего с нагнетания
                  циркуляционных компрессоров, регистрируется прибором{" "}
                  <strong>QJRA-520</strong>. Давление ВСГ на нагнетании
                  циркуляционных компрессоров регистрируется прибором
                  <strong>PJR-444</strong>. Температура ВСГ в сепараторе С-7
                  регистрируется прибором <strong>TJR-16/11</strong>.
                </p>
                <p>
                  Давление ГПС на входе в реактор Р-2/II регистрируется прибором
                  <strong>PJR-421</strong>. Перепад давления по реактору Р-2/II
                  регистрируется прибором <strong>PDJRA-422/2</strong> c
                  сигнализацией по максимальному значению. Давление ГПС на входе
                  в реактор Р-3/II регистрируется прибором{" "}
                  <strong>PJR-422</strong>. Перепад давления по реактору Р-3/II
                  регистрируется прибором
                  <strong>PDJRA-422/3</strong> c сигнализацией по максимальному
                  значению. Давление ГПС на выходе из реактора Р-3/II
                  регистрируется прибором <strong>PJR-423</strong>.
                </p>
                <p>
                  Из реактора Р-3/II ГПС поступает последовательно в трубное
                  пространство теплообменника Т-4/II, рибойлера Т-5,
                  теплообменников Т-3/II, Т-2/II, Температура ГПС на выходе из
                  реактора Р-3/II регистрируется прибором{" "}
                  <strong>TJRА-16/25</strong> с сигнализацией по максимальному
                  значению.
                </p>
                <p>
                  Для поддержания температурного режима в стабилизационной
                  колонне К-4, часть ГПС из теплообменника Т-4/II направляется в
                  трубное пространство рибойлера Т-5, далее поступает в трубное
                  пространство теплообменника Т-3/II и далее по описанной выше
                  схеме. В теплообменнике Т-4/II происходит охлаждение ГПС. В
                  рибойлере Т-5 часть ГПС охлаждается и после смешения с
                  байпасным потоком ГПС поступает в трубное пространство
                  теплообменника Т-3/II. Температура ГПС на выходе из Т-5
                  регистрируется прибором
                  <strong>TJR-17/02</strong>. В теплообменнике Т-3/II происходит
                  охлаждение ГПС, температура ГПС из теплообменника Т-3/II
                  регистрируется прибором <strong>TJR-16/03</strong>. В
                  теплообменнике Т-2/II происходит охлаждение ГПС.
                </p>
                <p>
                  Температура ГПС на входе в трубное пространство рибойлера Т-5
                  регулируется прибором TRС-403, клапан-регулятор которого
                  расположен на трубопроводе байпаса ГСС помимо теплообменника
                  Т-4/II, и регистрируется прибором <strong>TJRА-16/01</strong>.
                  Температура на выходе ГПС из трубного пространства рибойлера
                  Т-5 регистрируется прибором <strong>TJR-17/02</strong>.
                  Температура на выходе ГПС из Т-2/II регистрируется прибором
                  <strong>TJR-16/06</strong>.
                </p>
                <p>
                  Далее ГПС поступает в трубное пространство Т-1/II, где
                  охлаждается, нагревая нестабильный катализат из С-7.
                </p>
                <p>
                  ГПС из трубного пространства теплообменника Т-1/II поступает в
                  воздушный холодильник ВХК-1/II, в межтрубное пространство
                  водяного холодильника Х-1/II, из которого поступает в
                  сепаратор С-7.
                </p>
                <p>
                  Температура ГПС на выходе из Т-1/II регистрируется прибором
                  <strong>TJR-16/07</strong>. Температура ГПС на выходе из
                  ВХК-1/II регистрируется прибором <strong>TJR-16/08</strong>.
                  Для регулирования мольного соотношения «водород-углеводороды»
                  технологической схемой предусмотрен трубопровод с выкида
                  компрессоров ПК-2, ПК-3, ПК-4 на вход в воздушный холодильник
                  ВХК-1/II. Количество ВСГ, байпасируемого помимо реакторного
                  блока, регистрируется прибором <strong>FJR-480</strong>.
                </p>
                <p>
                  Температура ГПС на выходе из водяного холодильника Х-1/II
                  регулируется прибором <strong>TRC-402</strong>,
                  клапан-регулятор которого расположен на трубопроводе обратной
                  охлаждающей воды из трубного пространства водяного
                  холодильника Х-1/II. Технологической схемой предусмотрено
                  отключение запорной арматурой водяного холодильника Х-1/II по
                  продукту в случае пропуска трубного пучка. Температура ГПС на
                  выходе из водяного холодильника Х-1/II также регистрируется
                  прибором <strong>TJR-16/09</strong>. Технологической схемой
                  предусмотрено отключение воздушного холодильника ВХК-1/II в
                  случае пропуска трубных секций.
                </p>
                <p>
                  В сепараторе С-7 происходит отделение нестабильного катализата
                  (БТКФ) от циркулирующего водородсодержащего газа.
                  Циркуляционный ВСГ поступает в приемный сепаратор С-4 и далее
                  на прием циркуляционных компрессоров ПК-2,3,4, откуда ВСГ,
                  после подпитки свежим ВСГ, поступает в тройник смешения.
                  Концентрация водорода в ВСГ, поступающего с нагнетания
                  циркуляционных компрессоров, регистрируется прибором{" "}
                  <strong>QJRA-520</strong>. Давление ВСГ на нагнетании
                  циркуляционных компрессоров регистрируется прибором
                  <strong>PJR-444</strong>. Температура ВСГ в сепараторе С-7
                  регистрируется прибором <strong>TJR-16/11</strong>.
                </p>
                <p>
                  Давление ГПС на входе в реактор Р-2/II регистрируется прибором
                  <strong>PJR-421</strong>. Перепад давления по реактору Р-2/II
                  регистрируется прибором <strong>PDJRA-422/2</strong> c
                  сигнализацией по максимальному значению. Давление ГПС на входе
                  в реактор Р-3/II регистрируется прибором{" "}
                  <strong>PJR-422</strong>. Перепад давления по реактору Р-3/II
                  регистрируется прибором
                  <strong>PDJRA-422/3</strong> c сигнализацией по максимальному
                  значению. Давление ГПС на выходе из реактора Р-3/II
                  регистрируется прибором <strong>PJR-423</strong>.
                </p>
                <p>
                  Из реактора Р-3/II ГПС поступает последовательно в трубное
                  пространство теплообменника Т-4/II, рибойлера Т-5,
                  теплообменников Т-3/II, Т-2/II, Температура ГПС на выходе из
                  реактора Р-3/II регистрируется прибором{" "}
                  <strong>TJRА-16/25</strong> с сигнализацией по максимальному
                  значению.
                </p>
                <p>
                  Для поддержания температурного режима в стабилизационной
                  колонне К-4, часть ГПС из теплообменника Т-4/II направляется в
                  трубное пространство рибойлера Т-5, далее поступает в трубное
                  пространство теплообменника Т-3/II и далее по описанной выше
                  схеме. В теплообменнике Т-4/II происходит охлаждение ГПС. В
                  рибойлере Т-5 часть ГПС охлаждается и после смешения с
                  байпасным потоком ГПС поступает в трубное пространство
                  теплообменника Т-3/II. Температура ГПС на выходе из Т-5
                  регистрируется прибором
                  <strong>TJR-17/02</strong>. В теплообменнике Т-3/II происходит
                  охлаждение ГПС, температура ГПС из теплообменника Т-3/II
                  регистрируется прибором <strong>TJR-16/03</strong>. В
                  теплообменнике Т-2/II происходит охлаждение ГПС.
                </p>
                <p>
                  Температура ГПС на входе в трубное пространство рибойлера Т-5
                  регулируется прибором TRС-403, клапан-регулятор которого
                  расположен на трубопроводе байпаса ГСС помимо теплообменника
                  Т-4/II, и регистрируется прибором <strong>TJRА-16/01</strong>.
                  Температура на выходе ГПС из трубного пространства рибойлера
                  Т-5 регистрируется прибором
                  <strong>TJR-17/02</strong>. Температура на выходе ГПС из
                  Т-2/II регистрируется прибором <strong>TJR-16/06</strong>.
                </p>
                <p>
                  Далее ГПС поступает в трубное пространство Т-1/II, где
                  охлаждается, нагревая нестабильный катализат из С-7.
                </p>
                <p>
                  ГПС из трубного пространства теплообменника Т-1/II поступает в
                  воздушный холодильник ВХК-1/II, в межтрубное пространство
                  водяного холодильника Х-1/II, из которого поступает в
                  сепаратор С-7.
                </p>
                <p>
                  Температура ГПС на выходе из Т-1/II регистрируется прибором
                  <strong>TJR-16/07</strong>. Температура ГПС на выходе из
                  ВХК-1/II регистрируется прибором <strong>TJR-16/08</strong>.
                  Для регулирования мольного соотношения «водород-углеводороды»
                  технологической схемой предусмотрен трубопровод с выкида
                  компрессоров ПК-2, ПК-3, ПК-4 на вход в воздушный холодильник
                  ВХК-1/II. Количество ВСГ, байпасируемого помимо реакторного
                  блока, регистрируется прибором <strong>FJR-480</strong>.
                </p>
                <p>
                  Температура ГПС на выходе из водяного холодильника Х-1/II
                  регулируется прибором <strong>TRC-402</strong>,
                  клапан-регулятор которого расположен на трубопроводе обратной
                  охлаждающей воды из трубного пространства водяного
                  холодильника Х-1/II. Технологической схемой предусмотрено
                  отключение запорной арматурой водяного холодильника Х-1/II по
                  продукту в случае пропуска трубного пучка. Температура ГПС на
                  выходе из водяного холодильника Х-1/II также регистрируется
                  прибором <strong>TJR-16/09</strong>. Технологической схемой
                  предусмотрено отключение воздушного холодильника ВХК-1/II в
                  случае пропуска трубных секций.
                </p>
                <p>
                  Давление ГПС на входе в реактор Р-2/II регистрируется прибором
                  <strong>PJR-421</strong>. Перепад давления по реактору Р-2/II
                  регистрируется прибором <strong>PDJRA-422/2</strong> c
                  сигнализацией по максимальному значению. Давление ГПС на входе
                  в реактор Р-3/II регистрируется прибором{" "}
                  <strong>PJR-422</strong>. Перепад давления по реактору Р-3/II
                  регистрируется прибором
                  <strong>PDJRA-422/3</strong> c сигнализацией по максимальному
                  значению. Давление ГПС на выходе из реактора Р-3/II
                  регистрируется прибором <strong>PJR-423</strong>.
                </p>
                <p>
                  Из реактора Р-3/II ГПС поступает последовательно в трубное
                  пространство теплообменника Т-4/II, рибойлера Т-5,
                  теплообменников Т-3/II, Т-2/II, Температура ГПС на выходе из
                  реактора Р-3/II регистрируется прибором{" "}
                  <strong>TJRА-16/25</strong> с сигнализацией по максимальному
                  значению.
                </p>
                <p>
                  Для поддержания температурного режима в стабилизационной
                  колонне К-4, часть ГПС из теплообменника Т-4/II направляется в
                  трубное пространство рибойлера Т-5, далее поступает в трубное
                  пространство теплообменника Т-3/II и далее по описанной выше
                  схеме. В теплообменнике Т-4/II происходит охлаждение ГПС. В
                  рибойлере Т-5 часть ГПС охлаждается и после смешения с
                  байпасным потоком ГПС поступает в трубное пространство
                  теплообменника Т-3/II. Температура ГПС на выходе из Т-5
                  регистрируется прибором
                  <strong>TJR-17/02</strong>. В теплообменнике Т-3/II происходит
                  охлаждение ГПС, температура ГПС из теплообменника Т-3/II
                  регистрируется прибором <strong>TJR-16/03</strong>. В
                  теплообменнике Т-2/II происходит охлаждение ГПС.
                </p>
                <p>
                  Температура ГПС на входе в трубное пространство рибойлера Т-5
                  регулируется прибором TRС-403, клапан-регулятор которого
                  расположен на трубопроводе байпаса ГСС помимо теплообменника
                  Т-4/II, и регистрируется прибором <strong>TJRА-16/01</strong>.
                  Температура на выходе ГПС из трубного пространства рибойлера
                  Т-5 регистрируется прибором
                  <strong>TJR-17/02</strong>. Температура на выходе ГПС из
                  Т-2/II регистрируется прибором <strong>TJR-16/06</strong>.
                </p>
                <p>
                  Далее ГПС поступает в трубное пространство Т-1/II, где
                  охлаждается, нагревая нестабильный катализат из С-7.
                </p>
                <p>
                  ГПС из трубного пространства теплообменника Т-1/II поступает в
                  воздушный холодильник ВХК-1/II, в межтрубное пространство
                  водяного холодильника Х-1/II, из которого поступает в
                  сепаратор С-7.
                </p>
                <p>
                  Температура ГПС на выходе из Т-1/II регистрируется прибором
                  <strong>TJR-16/07</strong>. Температура ГПС на выходе из
                  ВХК-1/II регистрируется прибором <strong>TJR-16/08</strong>.
                  Для регулирования мольного соотношения «водород-углеводороды»
                  технологической схемой предусмотрен трубопровод с выкида
                  компрессоров ПК-2, ПК-3, ПК-4 на вход в воздушный холодильник
                  ВХК-1/II. Количество ВСГ, байпасируемого помимо реакторного
                  блока, регистрируется прибором <strong>FJR-480</strong>.
                </p>
                <p>
                  Температура ГПС на выходе из водяного холодильника Х-1/II
                  регулируется прибором <strong>TRC-402</strong>,
                  клапан-регулятор которого расположен на трубопроводе обратной
                  охлаждающей воды из трубного пространства водяного
                  холодильника Х-1/II. Технологической схемой предусмотрено
                  отключение запорной арматурой водяного холодильника Х-1/II по
                  продукту в случае пропуска трубного пучка. Температура ГПС на
                  выходе из водяного холодильника Х-1/II также регистрируется
                  прибором <strong>TJR-16/09</strong>. Технологической схемой
                  предусмотрено отключение воздушного холодильника ВХК-1/II в
                  случае пропуска трубных секций.
                </p>
                <p>
                  В сепараторе С-7 происходит отделение нестабильного катализата
                  (БТКФ) от циркулирующего водородсодержащего газа.
                  Циркуляционный ВСГ поступает в приемный сепаратор С-4 и далее
                  на прием циркуляционных компрессоров ПК-2,3,4, откуда ВСГ,
                  после подпитки свежим ВСГ, поступает в тройник смешения.
                  Концентрация водорода в ВСГ, поступающего с нагнетания
                  циркуляционных компрессоров, регистрируется прибором{" "}
                  <strong>QJRA-520</strong>. Давление ВСГ на нагнетании
                  циркуляционных компрессоров регистрируется прибором
                  <strong>PJR-444</strong>. Температура ВСГ в сепараторе С-7
                  регистрируется прибором <strong>TJR-16/11</strong>.
                </p>
                <h4>БЛОК СТАБИЛИЗАЦИИ</h4>
                <p>
                  Нестабильный катализат из сепаратора С-7 самотеком через
                  межтрубное пространство теплообменника Т-1/II, где нагревается
                  газопродуктовой смесью, выходящей из трубного пространства
                  теплообменника Т-2/II, направляется в межтрубное пространство
                  теплообменника Т-1\I, где нагревается стабильным катализатом
                  (бензол-толуол-ксилольным концентратом) поступающим с низа
                  колонны К-4. Далее нестабильный катализат самотеком через
                  межтрубное пространство теплообменника Т-26а, где нагревается
                  стабильным катализатом, выходящим с низа стабилизационной
                  колонны К-4, и направляется на загрузку в колонну К-4 на 17,
                  19, 21 тарелку, температура нестабильного катализата после
                  Т-26 регистрируется прибором TJR-17/06.
                </p>
                <p>
                  Уровень жидких углеводородов в сепараторе С-7 регулируется
                  прибором LJRCA-495, клапан-регулятор которого установлен на
                  трубопроводе жидкой фазы из С-7 в межтрубное пространство
                  Т-1/II и связан с сигнализацией по максимальному и
                  минимальному значениям, на линии нестабильного катализата из
                  С-7 установлен отсекатель HС-420.
                </p>
                <p>
                  Расход жидких углеводородов из сепаратора С-7 регистрируется
                  прибором FJR-464. Технологической схемой предусмотрен
                  трубопровод с выкида сырьевых насосов ЦН-2, ЦН-3 в трубопровод
                  жидких углеводородов с сепаратора С-7 для налаживания
                  циркуляции толуола в предпусковой период по отделениям
                  стабилизации и фракционирования. Для отключения сепаратора С-7
                  по жидким углеводородам от отделений стабилизации и
                  фракционирования на трубопроводе жидкой фазы из С-7 в Т-1/II
                  установлен клапан – отсекатель HС-420. Технологической схемой
                  предусмотрено отключение теплообменника Т-1/II от
                  технологической схемы по трубному и межтрубному пространствам
                  для ремонта.
                </p>
                <p>
                  Стабилизационная колонна К-4 оборудована в нижней части 20ю
                  клапанными балластными тарелками, в верхней части-18ю
                  тарелками S-образного типа.. В колонне К-4 происходит
                  отделение от катализата растворенных в нем водорода, метана и
                  других углеводородных газов. Углеводородные пары и газы с
                  верхней тарелки колонны проходят воздушный
                  конденсатор-холодильник ВХК-3, межтрубное пространство
                  водяного конденсатора-холодильника ХК-3, охлаждаются и
                  поступают в ёмкость орошения Е-6, из которой жидкая фаза
                  поступает на приём насосов ЦН-6,7, а газовая фаза направляется
                  в линию сухого газа.
                </p>
                <p>
                  Температура низа колонны К-4 регулируется прибором TRC-401,
                  клапан-регулятор которого расположен на трубопроводе байпаса
                  теплоносителя (ГПС) помимо трубного пучка подогревателя Т-5
                  колонны К-4 и регистрируется прибором TJR-17/03.
                </p>
                <p>
                  Температура углеводородных газов на выходе с верха К-4
                  регистрируется прибором TJR-17/05.Температура после воздушного
                  конденсатора – холодильника ВХК-3 регулируется прибором
                  TRC-406, посредством открытия-закрытия жалюзийных шиберов,
                  расположенных в верхней части аппарата над секциями и
                  регистрируется прибором TJR-16/13.
                </p>
                <p>
                  Температура паров углеводородов, выходящих из водяного
                  холодильника ХК-3, регулируется прибором TRC-405,
                  клапан-регулятор которого расположен на трубопроводе выхода
                  воды из трубного пространства водяного холодильника ХК-3, и
                  регистрируется прибором TJR-17/04.
                </p>
                <p>
                  Давление в колонне К-4 регистрируется прибором PJRA-435 c
                  сигнализацией по максимальному значению. Давление в системе
                  К-4→ВХК-3→ХК-3→Е-6 регулируется прибором PJRC-442,
                  клапаны-регуляторы которого расположены на трубопроводах
                  углеводородного газа из Е-6 в:
                </p>
                <ol>
                  <li>К-2, К-3 для газовой «подушки»;</li>
                  <li>на газовую гребенку.</li>
                </ol>
                <p>
                  Жидкая фаза из сепаратора Е-6 (парафино-бензольная фракция)
                  поступает на прием насосов ЦН-6, ЦН-7, откуда подается на
                  орошение стабилизационной колонны К-4. Количество подаваемого
                  орошения в колонну К-4 поддерживается прибором FJRC-465,
                  клапан-регулятор которого расположен на выкидном трубопроводе
                  насосов ЦН-6, ЦН-7. Уровень в сепараторе Е-6 регулируется и
                  контролируется приборами LJRA-491А, LRCA-491, клапан –
                  регулятор которых расположен на выкидном трубопроводе насосов
                  ЦН-6, ЦН-7 по периодическому выводу избытка жидкой фазы из
                  сепаратора Е-6. Стабильный катализат из рибойлера Т-5 проходит
                  через трубное пространство теплообменника Т-26а, поступает на
                  приём насосов ЦН-42,43 и далее направляется в отделение
                  очистки и ректификации.
                </p>
                <p>
                  Уровень в рибойлере Т-5 регулируется прибором LRCA-492,
                  клапан-регулятор которого расположен на трубопроводе
                  стабильного катализата из адсорбера К-101 в трубное
                  пространство теплообменника Т-4/1, с сигнализацией
                  минимального и максимального значения и связан коррекцией с
                  прибором FJRС-466 по загрузке в колонну К-12. Уровень в
                  рибойлере Т-5 также регистрируется прибором LJRA-492А с
                  сигнализацией минимального и максимального значения.
                  Температура перетока газо-жидкостной фазы из колонны К-4 в
                  рибойлер Т-5 регистрируется прибором TJR-17/01.
                </p>
                <h4>БЛОК ОЧИСТКИ И РЕКТИФИКАЦИИ</h4>
                <p>
                  Стабильный катализат с выкида насоса ЦН-42,43 поступает в
                  адсорбер К-101 для очистки от непредельных углеводородов на
                  отбеливающих глинах, на линии выкида насосов ЦН-42,43
                  установлен запорный (отсечной) клапан <strong>HС-419</strong>.
                  Для максимального увеличения срока службы глины, в нормальных
                  условиях, адсорбер эксплуатируется последовательно. Первая по
                  потоку секция используется до полного истощения глины, а
                  вторая – для дополнительной очистки. После истощения глины в
                  первой по потоку секции, она загружается свежей глиной и
                  включается в работу как вторая по потоку секция.
                </p>
                <p>
                  В адсорбер К-101 загружено 24 тонны адсорбента Filtrol-24.
                  Оптимальная температура в слое адсорбента должна
                  поддерживаться&nbsp; в пределах 150÷200 °С. При температуре
                  ниже 150 °С адсорбционная способность отбеливающих глин
                  остается высокой, но каталитическая активность настолько
                  низка, что происходит сокращение срока службы адсорбента.
                  Температура стабильного катализата на выходе из каждой секции
                  регистрируется приборами <strong>TJR-17/21, </strong>
                  <strong>TJR-17/22</strong>. Давление стабильного катализата на
                  входе в каждую секцию регистрируется приборами
                  <strong>PJR-455/1, </strong>
                  <strong>PJR-455/2</strong>.
                </p>
                <p>
                  &nbsp;Из адсорберов К-101 очищенный стабильный катализат
                  поступает в трубное пространство теплообменника Т-1/I, где
                  охлаждается, нагревая в межтрубном пространстве нестабильный
                  катализат, и направляется в бензольную колонну К-12.
                  Расход&nbsp; стабильного катализата в К-12 регулируется
                  клапаном <strong>FJRC-466</strong>
                  по уровню в Т-5.
                </p>
                <p>
                  Температура стабильного катализата из трубного пространства
                  Т-1/I регистрируется прибором <strong>TJR-17/24</strong>.
                </p>
                <p>
                  Колонна К-12 оборудована 50-ю тарелками S-образного типа. В
                  колонне К-12 происходит выделение из стабильного катализата
                  бензола и незначительного количества парафиновых
                  углеводородов. Углеводородные пары с верхней тарелки поступают
                  в межтрубное пространство водяного конденсатора-холодильника
                  ХК-9, где конденсируются, охлаждаются и выводятся в ёмкость
                  орошения Е-27, из которой жидкость поступает на приём насосов
                  ЦН-31,32.
                </p>
                <p>
                  Температура на «характерных» тарелках К-12&nbsp;
                  регистрируется приборами <strong>TJR-17/32</strong> (40-я
                  тарелка),
                  <strong>TJR-17/33</strong> (35-я тарелка),
                  <strong>TJR-17/34</strong> (20-я тарелка),
                  <strong>TJR-17/35</strong> (5-я тарелка). Температура верха
                  колонны К-12 регистрируется прибором{" "}
                  <strong>TJR-17/31</strong>. Давление верха колонны К-12
                  регистрируется прибором
                  <strong>PJRA-449</strong>, с сигнализацией по максимальному
                  значению.
                </p>
                <p>
                  Температура газоконденсатной смеси из ХК-9 регулируется
                  прибором
                  <strong>TRC-407</strong>, клапан-регулятор которого расположен
                  на трубопроводе выхода охлаждающей воды из трубного
                  пространства ХК-9, а также регистрируется прибором{" "}
                  <strong>TJR-17/38</strong>.
                </p>
                <p>
                  Давление в системе К-12→ХК-9→Е-27 поддерживается прибором
                  <strong>PJRC-443</strong>, клапан-регулятор которого
                  расположен на трубопроводе вывода углеводородного газа из Е-27
                  в трубопровод факела и далее в Е-7.
                </p>
                <p>
                  На верхнюю тарелку колонны К-12 насосами ЦН-31, 32 подаётся
                  орошение, а балансовое количество бензольной фракции через
                  межтрубное пространство холодильника&nbsp;&nbsp;&nbsp;&nbsp;
                  Х-19 отправляется в емкости 415/203, Е-200Н установки
                  «Изомеризация ксилолов», расход бензольной фракции с установки
                  контролируется прибором <strong>FQJR-477</strong>.
                  Технологической схемой предусмотрен трубопровод вывода
                  бензольной фракции в сырьевые емкости К-2, К-3 в период
                  пусковых операций, а также вывод в трубопровод катализата с
                  установки Л-35/11-300 и далее на установку «Суммарные
                  ксилолы»;
                </p>
                <p>
                  Температура бензольной фракции регистрируется прибором&nbsp;
                  <strong>TJR-16/45</strong> и регулируется прибором
                  <strong>TRC-410</strong>, клапан-регулятор которого установлен
                  на трубопроводе&nbsp; обратной охлаждающей воды из трубного
                  пучка холодильника Х-19. Расход орошения в колонну К-12
                  регулируется прибором <strong>FJRC-467</strong>,
                  клапан-регулятор которого установлен на выкидном трубопроводе
                  насосов ЦН-31, ЦН-32.
                </p>
                <p>
                  Нижний продукт (смесь толуола, ксилолов и ароматических С
                  <sub>9</sub>) колонны К-12 насосами ЦН-25а, 26а подаётся в
                  толуольную колонну К-13, расход сырья в К-13 регулируется
                  клапаном
                  <strong>FJRC-481</strong> по уровню в К-12.
                </p>
                <p>
                  Уровень в колонне К-12 регулируется прибором
                  <strong>LRCA-499</strong>, клапан-регулятор которого
                  расположен на выкидном трубопроводе от насосов ЦН-25а, ЦН-26а
                  на загрузку в колонну К-13 с сигнализацией минимального и
                  максимального значения, и дополнительно регистрируется
                  прибором
                  <strong>LJRA-499А</strong> с сигнализацией минимального и
                  максимального значения.
                </p>
                <p>
                  Для обеспечения необходимой температуры низа колонны К-12
                  установлен паровой подогреватель Т-14. Температура низа
                  колонны К-12 регулируется прибором <strong>TRC-409</strong>,
                  клапан-регулятор которого установлен на трубопроводе парового
                  конденсата из межтрубного пространства подогревателя Т-14, а
                  также регистрируется прибором <strong>TJR-17/36</strong>.
                </p>
                <p>
                  Колонна К-13 оборудована 50-ю тарелками S-образного типа. В
                  колонне К-13&nbsp; происходит выделение толуола.
                  Углеводородные пары с верхней тарелки поступают в трубное
                  пространство воздушного конденсатора-холодильника ВХК-2 и
                  далее в межтрубное пространство водяного
                  конденсатора-холодильника ХК-10, где конденсируются,
                  охлаждаются и выводятся в ёмкость орошения Е-28, из которой
                  жидкость поступает на приём насосов ЦН-33,34, температура
                  толуола после ВХК-2 контролируется
                  прибором&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <strong>TJR-17/39</strong>.
                </p>
                <p>
                  На верхнюю тарелку колонны К-13 насосами ЦН-33, 34 подаётся
                  орошение, а балансовое количество толуола подаётся в линию
                  комбинированного сырья установки.
                </p>
                <p>
                  Загрузка в колонну К-13 регулируется прибором
                  <strong>FJRC-481</strong> c коррекцией по уровню в колонне
                  К-12. Температура на «характерных» тарелках К-13
                  регистрируется приборами <strong>TJR-17/46</strong> (40-я
                  тарелка),
                  <strong>TJR-17/45</strong> (35-я тарелка),
                  <strong>TJR-17/44</strong> (25-я тарелка),
                  <strong>TJR-17/43</strong> (5-я тарелка). Температура верха
                  колонны К-13 регистрируется прибором TJR-17/47. Давление верха
                  колонны К-13 регистрируется прибором <strong>PJRA-454</strong>
                  , с сигнализацией по максимальному значению.
                </p>
                <p>
                  Температура газоконденсатной смеси из ХК-10 регулируется
                  прибором
                  <strong>TRC-411</strong>, клапан-регулятор которого расположен
                  на трубопроводе выхода охлаждающей воды из трубного
                  пространства ХК-10, а также регистрируется прибором{" "}
                  <strong>TJR-17/41</strong>.
                </p>
                <p>
                  Для поддержания избыточного давления в Е-28 технологической
                  схемой предусмотрена подача азота низкого давления в емкость
                  Е-28 (азотная «подушка»). Давление в Е-28 регулируется
                  прибором
                  <strong>PJRC-452</strong>, клапана-регуляторы которого
                  расположены на трубопроводе подачи азота в Е-28 и трубопроводе
                  сброса избыточного азота на свечу Е-28. Уровень в емкости Е-28
                  регулируется прибором <strong>LRCA- 494</strong> с
                  сигнализацией максимального значения, клапан-регулятор
                  которого расположен на трубопроводе выхода толуольной фракции
                  в трубопровод толуола на установке и дополнительно
                  регистрируется прибором
                  <strong>LJRA-494А</strong> с сигнализацией максимального и
                  минимального значения.
                </p>
                <p>
                  Технологической схемой предусмотрен трубопровод вывода
                  толуольной фракции в трубопровод катализата с установки
                  Л-35/11-300 и далее на установку «Суммарные ксилолы».
                </p>
                <p>
                  Расход толуольной фракции с установки учитывается прибором
                  <strong>FQJR-483</strong>. Температура толуольной фракции
                  регистрируется прибором <strong>TJR-16/57</strong>.
                </p>
                <p>
                  Расход орошения в колонну К-13 регулируется прибором
                  <strong>FJRC-482</strong>, клапан-регулятор которого
                  установлен на выкидном трубопроводе насосов ЦН-33, ЦН-34.
                </p>
                <p>
                  Для обеспечения необходимой температуры низа колонны К-13
                  установлен паровой рибойлер Т-14а. Температура низа колонны
                  К-13 регулируется прибором <strong>TRC-400</strong>,
                  клапан-регулятор которого установлен на трубопроводе парового
                  конденсата из межтрубного пространства подогревателя Т-14а, а
                  также регистрируется прибором <strong>TJR-17/42</strong>.
                </p>
                <p>
                  Из рибойлера Т-14а ксилольная фракция поступает на прием
                  насосов ЦН-29, ЦН-30.
                </p>
                <p>
                  С выкидного трубопровода насосов ЦН-29, ЦН-30 ксилольная
                  фракция проходит трубное пространство подогревателей Х-20,
                  Т-19, в которых охлаждается комбинированным сырьем установки,
                  и межтрубное пространство водяного холодильника Х-21.
                </p>
                <p>
                  Уровень в рибойлере Т-14а регулируется прибором
                  <strong>LRCA-493</strong>, клапан-регулятор которого
                  установлен на трубопроводе выкида насосов ЦН-29, ЦН-30 с
                  сигнализацией максимального и минимального значения.
                  Дополнительно уровень в рибойлере&nbsp;&nbsp;&nbsp; Т-14а
                  регистрируется прибором
                  <strong>LJRA-493А</strong> с сигнализацией максимального и
                  минимального значения.
                </p>
                <p>
                  Температура ксилольной фракции после подогревателя Т-19
                  регистрируется прибором <strong>TJR-16/42</strong>.
                  Технологической схемой предусмотрено байпасирование
                  подогревателей Х-20, Т-19 по трубному пучку для ремонта.
                  Технологической схемой предусмотрен трубопровод вывода
                  ксилольной фракции в сырьевые емкости К-2, К-3 для налаживания
                  циркуляции по отделению ректификации, а также вывод ксилольной
                  фракции в трубопровод катализата с установки Л-35/11-300 и
                  далее на установку «Суммарные ксилолы».
                </p>
                <p>
                  Ксилольная фракция после холодильника Х-21 поступает с
                  установки:
                </p>
                <p>
                  - некондиция - в резервуары №№417, 428, 429 установки
                  «Суммарные ксилолы»;
                </p>
                <p>
                  - качественная продукция – в резервуары №№506, 515, 516
                  установки «Ортоксилол» .
                </p>
                <p>
                  Температура ксилольной фракции по сле Х-21 регулируется
                  прибором
                  <strong>TRC-408</strong>, клапан–регулятор которого установлен
                  н а линии выхода оборотной воды из Х-21 и контролируется
                  прибором
                  <strong>TJR-16/46</strong>.
                </p>
                <p>
                  Расход кс илольной фракции с установки учитывается прибором
                  <strong>FQJR-473</strong>.
                </p>
                <p>
                  Качество ксилольной фракции с установки контролируется
                  прибором
                  <strong>QR-522</strong>.
                </p>
                <h3>ЗАГРУЗКА КАТАЛИЗАТОРА</h3>
                <img src="img/tatorei1.afea12f2.jpg" alt="" />
                <img src="img/tatorei2.2197594e.png" alt="" />
              </div>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: "#52527a;", color: "white" }}>
                Компьютерная модель установки
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <ModalComponent
                name="Компьютерная модель установки"
                href="https://drive.google.com/file/d/1vZiHrsBFUSuGSP2vig-coibiusJfHqOn/preview"
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: "#52527a;", color: "white" }}>
                Схема технологического процесса
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <ModalComponent
                name="Схема технологического процесса"
                href="https://drive.google.com/file/d/13hiwCDLyACP42Vmr-sQ69zbm_-JcKYgQ/preview"
              />
            </AccordionPanel>
          </AccordionItem>
          {/* <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: "#52527a;", color: "white" }}>
                Тест
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
<Test/>
            </AccordionPanel>
          </AccordionItem> */}
        </Accordion>
      </div>
      <HydrotreatingSwiper />
    </div>
  );
}

export default tatorey;
