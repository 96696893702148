import React from "react";
import "./vt-1.scss";
import HydrotreatingSwiper from "../hydrotreating/hydrotreatingSwiper";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

import ModalComponent from "../ModalComponent/ModalComponent";
import Test from "../Test";

function vt1() {
  return (
    <div className="vt1">
      <div className="content">
        <h1>ВТ-1</h1>
        <Accordion allowToggle width="100%">
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: "#52527a;", color: "white" }}>
                Теоретический материал
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <div className="vt1__modal">
                <h3>Общая характеристика производства</h3>
                <p>
                  Установка вакуумной разгонки мазута ВТ-1 предназначена для
                  переработки прямогонных мазутов с целью получения вакуумных
                  дистиллятов, гудрона и верхнего гудрона - сырья для
                  производства смазочных масел, присадок и битумов.
                </p>
                <p>
                  <strong></strong>
                </p>
                <p>
                  <strong>Общая характеристика производимой продукции</strong>
                </p>
                <p>
                  Основная продукция установки - вакуумные дистилляты ВД-1,
                  ВД-2, ВД-3, ВД-4 используют для производства базовых и
                  смазочных масел и других нефтепродуктов, могут вовлекать в
                  сырье установки гидрокрекинга, смешивать с прочими вакуумными
                  газойлями или отгружать потребителям. Гудрон и верхний гудрон
                  используют для производства остаточного компонента масел и
                  нефтяных битумов, а также как компонент топочных мазутов или
                  сырья установки висбрекинга.
                </p>
                <p>
                  Полупродуктом установки являются компонент дизельного топлива
                  и лёгкий компонент дизтоплива, используемые в приготовлении
                  нефтяных топлив. Газы разложения используются в качестве
                  компонента газообразного топлива на самой установке ВТ-1.
                </p>
                <p>
                  <strong>
                    Описание технологического процесса и схемы производства
                  </strong>
                </p>
                <p>
                  Мазут - сложная смесь углеводородов с высокой температурой
                  кипения, содержащая соединения углерода, водорода, серы,
                  азота, кислорода и других элементов.
                </p>
                <p>
                  Переработка мазута производится под вакуумом и с подачей
                  водяного пара.
                </p>
                <p>
                  Углеводородные пары поступают в ректификационную секцию
                  колонны, где контактируют с внутренним орошением; жидкость
                  поступает в нижнюю часть, где контактирует с острым водяным
                  паром.
                </p>
                <p>
                  Из-за разной степени летучести отдельных компонентов сырья
                  пары над жидкостью имеют другой состав (большее содержание
                  летучих компонентов), чем жидкость. Путем многократного
                  частичного испарения и конденсирования на тарелках (насадках)
                  колонны получают дистилляты требуемого качества. При данном
                  процессе пары попадают на верхнюю тарелку (слой насадки), а
                  неиспарившаяся жидкость стекает вниз на нижнюю тарелку (слой
                  насадки). Скрытое тепло паров, идущих с нижней тарелки (слоя
                  насадки), используется для испарения жидкости; скрытое тепло
                  жидкости, перетекающей вниз с верхней тарелки (слоя насадки),
                  используется для конденсирования паров. Возврат части
                  дистиллята в верх колонны в виде орошения увеличивает
                  фракционирующую способность слоев насадки в колонне.
                </p>
                <p>
                  Боковые продукты колонны вступают в контакт с парами более
                  легких фракций. Небольшое количество более легких продуктов
                  находится в растворенном виде в каждой из фракций. Легкие
                  компоненты дистиллятов в продукте понижают температуру вспышки
                  и начала кипения фракции, поэтому боковые продукты из главной
                  колонны направляются в отпарные колонны, где проходят отпарку
                  перегретым водяным паром.
                </p>
                <p>
                  Пар также подается в змеевики печи и в низ вакуумной колонны,
                  чтобы уменьшить парциальное давление углеводородных паров и
                  увеличить степень их испарения. Водяной пар подается в
                  змеевики печи для увеличения линейной скорости в трубах, что
                  также снижает образование кокса в трубах.
                </p>
                <p>
                  <em>Параметры процесса</em>
                </p>
                <p>
                  Основные параметры работы вакуумной перегонки являются
                  следующими:
                </p>
                <ul>
                  <li>вакуум внутри колонны</li>
                  <li>температура в зоне ввода сырья</li>
                  <li>боковые и внутренние орошения</li>
                  <li>
                    подача водяного пара в куб колонны и температура куба
                    колонны
                  </li>
                  <li>отбор продуктов</li>
                </ul>
                <p>
                  При оценке результатов изменения одной из данных характеристик
                  необходимо принять в расчет, что каждая из них оказывает
                  некоторое влияние на остальные, и в свою очередь, находится
                  под их влиянием.
                </p>
                <p>
                  <em>Вакуум внутри колонны</em>
                </p>
                <p></p>
                <p>
                  Глубина вакуума внутри колонны очень сильно влияет на качество
                  фракционирования, позволяя иметь более низкую температуру на
                  выходе из печи, в результате чего удается избежать проблемы с
                  образованием крекинг газа и кокса в трубах печи и в низу
                  колонны.
                </p>
                <p>
                  <em>Температура в зоне ввода сырья</em>
                </p>
                <p></p>
                <p>
                  Температура в зоне ввода сырья устанавливается исходя из
                  качества перерабатываемого мазута и давления в зоне ввода
                  сырья. Температура в зоне ввода сырья находится в пределах
                  350-384̊̊ C
                </p>
                <p>
                  <em>Боковые и внутренние орошения</em>
                </p>
                <p></p>
                <p>
                  Расход верхнего циркуляционного орошения (ВЦО) и расход
                  горячего орошения (на 2-й пакет) позволяют поддерживать прежде
                  всего температуру в верхней части колонны на низком уровне,
                  что сокращает до минимума количество углеводородных паров,
                  выходящих из верха колонны в газы разложения.
                </p>
                <p>
                  <em>
                    Подача водяного пара и температура куба колонны - "квенчинг"
                  </em>
                </p>
                <p></p>
                <p>
                  Пар высокого давления подается в змеевики печи, а перегретый
                  пар низкого давления в нижнюю часть вакуумной колонны, ниже
                  отпарной секции.
                </p>
                <p>
                  <strong>Описание технологической схемы</strong>
                </p>
                <p></p>
                <p>
                  Сырье установки - мазут с установок первичной переработки
                  нефти поступает в сырьевые резервуары Е-1 Е-2 Е-3. Сырье из
                  резервуаров подается на прием насосов, и далее проходит ряд
                  теплообменников, где нагревается встречным продуктом и
                  поступает в печь F-01/2.
                </p>
                <p>
                  Трубчатая печь состоит из двух камер F-01/1, F-01/2. Каждая из
                  камер состоит из секций конвекции и радиации. Через каждую
                  камеру проходят четыре параллельно соединенных потока,
                  предназначенных для подогрева сырья, которое проходит через
                  конвекционную, а затем радиантную секции. На выходе из печи
                  отдельные потоки сырья объединяются в трансферный трубопровод
                  и поступают в эвапорационную зону вакуумной колонны. На входе
                  в змеевики печи подается водяной пар для турбулизации потоков
                  сырья и предотвращения коксообразования. Расход пара на
                  турбулизацию в каждый из потоков регулируется клапанами
                  регуляторами поз.01FV-037…01FV-044. В конвекции каждой камеры
                  размещен змеевик (пароперегреватель), предназначенный для
                  перегрева пара низкого давления из Д-02. Перегретый водяной
                  пар подается в колонны К-01, К-03/1…4.
                </p>
                <p>
                  Каждая камера оснащена шестью комбинированными горелками для
                  сжигания жидкого топлива и топливного газа. Каждая из горелок
                  оснащена пилотной горелкой. Управление процессом горения
                  обеспечивается управляющей системой, самостоятельной для
                  каждой камеры.
                </p>
                <p>
                  Расход сырья в потоки печи контролируется и регистрируется
                  поз.
                  <strong>
                    <sup>#</sup>FJRCA<sub>L</sub>-001…008/01.
                  </strong>
                </p>
                <p>
                  <strong></strong>Расход сырья в потоки регулируется клапанами
                  - регуляторами поз.01FV001…01FV008. Расход водяного пара в
                  потоки печи контролируется и регистрируется поз.FJRCA
                  <sub>L</sub>-037…044, сигнализация срабатывает при снижении
                  расхода пара до 150кг/ч.
                </p>
                <p>
                  Расход водяного пара в потоки регулируется клапанами -
                  регуляторами поз.01FV037…01FV044. Температура пара в потоки
                  печи F-01/1,2 контролируется и регистрируется поз.TJR-079/01,
                  TJR-080/01. В пароперегреватель, который расположен в
                  конвекционной части печи, подается насыщенный пар низкого
                  давления из Д-02 для его перегрева. Расход пара контролируется
                  поз.FJR-059/01, FJR-060/01. Температура перегретого пара
                  контролируется и регистрируется поз.TJR-083/01, TJR-084/01.
                </p>
                <p>
                  Температура сырья на выходе из каждой камеры печи F-01/1,2
                  контролируется и регистрируется поз.TJR-081/01, TJR-082/02.
                  <strong></strong>
                </p>
                <p></p>
                <p>
                  Топливный газ на установку поступает из заводской сети в
                  сепаратор Е-108.
                </p>
                <p>
                  Сепаратор Е-108 предназначен для отделения газового
                  конденсата, который сбрасывается в факельную емкость Е-113.
                </p>
                <p>
                  Топливный газ после сепаратора Е-108 поступает для подогрева в
                  межтрубное пространство теплообменника Е-45.
                </p>
                <p>
                  Топливный газ после Е-45 двумя потоками направляется в печь
                  F-01/1,2.
                </p>
                <p>
                  Пилотный газ берется из линии топливного газа после Е-45,
                  двумя параллельными потоками поступает к пилотным горелкам
                  печи.
                </p>
                <p>
                  Из печи F-01/1,2 нагретое сырье подается в эвапорационную зону
                  колонны К-01. Температура паро-жидкостной смеси составляет до
                  384°C при давлении до 97кПа. Температура сырья на входе в
                  колонну контролируется поз.TJRZA-114/01.
                </p>
                <p>
                  Жидкость из зоны ввода сырья перетекает через пять отпарных
                  тарелок в нижнюю секцию вакуумной колонны.
                </p>
                <p>
                  С целью снижения содержания летучих компонентов в гудроне, в
                  колонну под нижнюю тарелку подается перегретый пар. Расход
                  перегретого пара измеряется и контролируется поз.FJRC-036/01,
                  регулируется клапаном регулятором поз.01FV-036.
                </p>
                <p>
                  С низа колонны К-01 вакуумный остаток (гудрон) поступает на
                  прием насосов Р-08/1,2 и откачивается последовательно через
                  теплообменники, где охлаждается водой и с температурой не
                  более 130°С выводится с установки.
                </p>
                <p>
                  Температура гудрона на выходе из колонны контролируется и
                  регистрируется поз.TJRА-054/01.
                </p>
                <p>
                  Для понижения температуры в низу колонны К-01 до355°С часть
                  охлажденного гудрона после Е-17/2,1 подается в низ колонны
                  К-01(квенчинг).
                </p>
                <p>
                  Расход квенчинга в К-01 контролируется и регистрируется
                  поз.FJRC-021/01 и регулируется клапаном - регулятором позиции
                  01FV-021.
                </p>
                <p>
                  Вакуум в зоне ввода сырья контролируется, регистрируется
                  поз.PJRZA
                  <sub>L</sub>-009/01, PJRZA<sub>L</sub>-058/01.
                </p>
                <p>
                  Над зоной ввода сырья расположена глухая тарелка. Из боковых
                  карманов глухой тарелки верхний гудрон по двум сливным трубам
                  стекает в низ К-01. Избыток этого продукта откачивается
                  насосом Р-07/1,2, через теплообменники и с температурой не
                  более 120 С выводится с установки.
                </p>
                <p>
                  Над глухой тарелкой расположен пакет №8. Насадка пакета №8
                  предотвращает унос капель жидкости из зоны ввода сырья в зону
                  вывода ВД-4 и определяет качество вакуумного дистиллята ВД-4.
                </p>
                <p>
                  Для обеспечения нормальной работы насадки пакета №8 на нее
                  постоянно подается горячее орошение по схеме:
                </p>
                <p>К-03/4 → P-06/1,2 → S-04/1,2 → над пакетом №8 в К-01.</p>
                <p>
                  Расход горячего орошения контролируется и регистрируется
                  поз.FJRCA-017/01, регулируется клапаном-регулятором
                  поз.01FV-017.
                </p>
                <p>
                  Над пакетом №8 расположена полуглухая тарелка, с которой
                  выводится ВД-4 в колонну К-03/4. В колонну К-03/4 подается
                  перегретый пар. Отпаренные легкие фракции возвращаются из
                  отпарной колонны обратно в вакуумную колонну К-01 ниже пакета
                  №7. Отпаренный ВД-4 c низа К-03/4 поступает на прием насосов
                  Р-06/1,2 и окачивается по схеме:
                </p>
                <p>
                  Р-06/1,2 → трубное пространство Е-15/1,2 → межтрубное
                  пространство Е-23 → межтрубное пространство Е-32/1,2 →
                  межтрубное пространство Е-36/1,2 и с температурой не более
                  100°С выводится с установки.
                </p>
                <p>
                  Насадка пакета №7 и №6 определяет качество вакуумных
                  дистиллятов ВД-3 и ВД-4. Орошение пакета насадки №-7
                  осуществляется через желобчатый распределитель самотеком. С
                  полуглухой тарелки, расположенной под пакетом №6 выводится
                  ВД-3 в колонну К-03/3 и отбирается нижнее циркуляционное
                  орошение. Нижнее циркуляционное орошение отбирает избыточное
                  тепло с пакета №6.
                </p>
                <p>
                  C сборной тарелки нижнее циркуляционное орошение отбирается
                  насосом Р-10/1,2 по схеме:
                </p>
                <p>
                  К-01 → Р-10/1,2 → трубное пространство Е-18/1,2 → трубное
                  пространство Е-13/2,1 и разделяется на два потока: один поток
                  направляется для доохлаждения через клапан- регулятор
                  температуры поз.01TV-006/1 в трубное пространство Е-11, другой
                  поток через клапан - регулятор температуры поз.01TV-006/2
                  байпасирует Е-11. Затем оба потока объединяются и через фильтр
                  S-03/1,2 поступает в К-01.
                </p>
                <p>
                  В колонну К-03/3 подается перегретый пар. Отпаренные легкие
                  фракции возвращаются из отпарной колонны обратно в вакуумную
                  колонну К-01 над пакет №7.
                </p>
                <p>
                  Отпаренный ВД-3 с низа К-03/3 поступает на прием насосов
                  Р-05/1,2 и окачивается по схеме:
                </p>
                <p>
                  Р-05/1,2 → трубное пространство Е-14/1,2 → межтрубное
                  пространство Е-22 → межтрубное пространство Е-21 → межтрубное
                  пространство Е-31/1,2 → межтрубное пространство Е-35/1,2 и с
                  температурой не более 100°С выводится с установки. Уровень в
                  колонне К-03/3 контролируется, регистрируется поз.LJRCSA
                  <sup>H</sup>
                  <sub>L</sub>-011A/01, LJRCSA<sup>H</sup>
                  <sub>L</sub>-011B/01 и регулируется клапаном-регулятором
                  поз.01LV-011.
                </p>
                <p>
                  Насадка пакета №5 и №4 определяет качество вакуумных
                  дистиллятов ВД-2 и ВД-3. Орошение пакета насадки №5
                  осуществляется через желобчатый распределитель самотеком. С
                  сборной тарелки, расположенной под пакетом №4 выводится ВД-2 в
                  колонну К-03/2. Уровень ВД-2 на тарелке, контролируется и
                  регистрируется поз.LJRA<sub>L</sub>-003A/01, LJRA
                  <sub>L</sub>-003B/01, сигнализация срабатывает 14%. ВД-2 с
                  сборной тарелки выводится в отпарную колонну К-03/2.
                </p>
                <p>
                  В колонну К-03/2 подается перегретый пар. Отпаренные легкие
                  фракции возвращаются из отпарной колонны обратно в вакуумную
                  колонну К-01 под 5-й пакет. Отпаренный ВД-2 c низа К-03/2
                  поступает на прием насосов Р-04/1,2 и окачивается по схеме:
                </p>
                <p>
                  Р-04/1,2 → трубное пространство Е-04 → межтрубное пространство
                  Е-30/1,2 → межтрубное пространство Е-34/1,2 и с температурой
                  не более 100°С выводится с установки. Уровень в колонне К-03/2
                  контролируется, регистрируется поз.LJRCSA<sup>H</sup>
                  <sub>L</sub>-010A/01, LJRСSA<sup>H</sup>
                  <sub>L</sub>-010B/01 и регулируется клапаном-регулятором
                  поз.01LV-010.
                </p>
                <p>
                  Насадка пакета №3 и №4 определяет качество вакуумных
                  дистиллятов ВД-1 и ВД-2. С сборной тарелки, расположенной под
                  пакетом №3 выводится вакуумный дистиллят ВД-1 в колонну К-03/1
                  и отбирается среднее циркуляционное орошение. Среднее
                  циркуляционное орошение отбирает избыточное тепло с пакета №3.
                </p>
                <p>
                  C сборной тарелки СЦО отбирается насосом Р-09/1,2 по схеме:
                </p>
                <p>
                  К-01 → Р-09/1,2 → трубное пространство Е-10/1,2 → трубное
                  пространство Е-08/2, трубное пространство Е-08/1 и разделяется
                  на два потока: один поток направляется для доохлаждения через
                  клапан - регулятор температуры поз.01TV-004/1 в трубное
                  пространство Е-05/1,2, другой поток через клапан - регулятор
                  температуры поз.01TV-004/2 байпасирует Е-05/1,2, затем оба
                  потока объединяются и через фильтр S-02/1,2 поступает в К-01.
                </p>
                <p>
                  ВД-1 с сборной тарелки выводится в отпарную колонну К-03/1. В
                  колонну К-03/1 подается перегретый пар. Отпаренные легкие
                  фракции возвращаются из отпарной колонны обратно в вакуумную
                  колонну К-01 под пакет №4.
                </p>
                <p>
                  Отпаренный ВД-1 с низа К-03/1 поступает на прием насосов
                  Р-03/1,2 и окачивается по схеме:
                </p>
                <p>
                  Р-03/1,2 → трубное пространство Е-03 → межтрубное пространство
                  Е-29/1,2 → межтрубное пространство Е-33/1,2 → с температурой
                  не более 100°С выводится с установки. Температура ВД-1 на
                  выходе из колонны К-01 контролируется и регистрируется
                  поз.TJRA-030/01.
                </p>
                <p>
                  Насадка пакета №1 и №2 определяет качество компонента
                  дизельного топлива и погона ВД-1.
                </p>
                <p>
                  С глухой тарелки, расположенной под пакетом №1 выводятся
                  следующие три потока:
                </p>
                <ul>
                  <li>верхнее горячее орошение;</li>
                  <li>верхнее циркуляционное орошение;</li>
                  <li>компонент дизельного топлива.</li>
                </ul>
                <p>
                  Схема циркуляции горячего орошения: К-01 → насос P-02/1,2 →
                  К-01 (на 2 пакет)
                </p>
                <p>
                  Расход верхнего горячего орошения контролируется,
                  регистрируется поз.FJRC-011/01 и регулируется клапаном -
                  регулятором поз.01FV-011. Температура верхнего горячего
                  орошения на входе в колонну К-01 контролируется и
                  регистрируется поз.TJR-022/01.
                </p>
                <p>
                  На 1-й пакет насадки подается верхнее циркуляционное орошение
                  по схеме:
                </p>
                <p>
                  К-01 → насос P-02/1,2 → трубное пространство E-02/2,1 →
                  трубное пространство E-01/2,1 и перед А-01 разделяется на два
                  потока: один поток направляется для доохлаждения через клапан
                  регулятор температуры поз.01TV-023/1 в A-01, другой поток
                  через клапан - регулятор температуры поз.01TV-023/2
                  байпасирует A-01, затем оба потока объединяются и через фильтр
                  S-01/1,2 поступает в К-01. Данный контур предназначен для
                  регулирования температуры верхнего циркуляционного орошения на
                  входе в колонну. Расход контролируется, регистрируется
                  поз.FJRC-009/01 и регулируется клапаном - регулятором
                  поз.01FV-009.
                </p>
                <p>
                  Температура верха колонны контролируется, регистрируется
                  поз.TJR-021/01 и регулируется клапанами - регуляторами
                  01TV-023/1,2 с коррекцией по температуре после А-01
                  поз.TJRC-023/01. Перепад давления на фильтрах S-01/1,2
                  контролируется и регистрируется поз.PdJRA
                  <sup>H</sup>-005/01, сигнализация срабатывает при 151кПа.
                </p>
                <p> Компонент дизельного топлива отводится по схеме:</p>
                <p>
                  К-01 → насос Р-02/1,2 → трубное пространство Е-02/2,1 →
                  трубное пространство Е-01/2,1 → А-01/1…4 → межтрубное
                  пространство Е-39 и с температурой не более 60˚С выводится с
                  установки. Уровень компонента дизельного топлива на глухой
                  тарелке контролируется и регистрируется поз.LJRCA<sub>L</sub>
                  -001A/01, LJRCA<sub>L</sub>-001B/01 и регулируется клапаном -
                  регулятором 01LV-001.
                </p>
                <p> С верха К-01 отводятся пары:</p>
                <ul>
                  <li>водяного пара;</li>
                  <li>газов разложения;</li>
                  <li>
                    легкого компонента дизельного топлива (ЛКДТоп) которые
                    поступают в вакуумсоздающую аппаратуру.
                  </li>
                </ul>
                <p>
                  Вакуум в верху колонны К-01 контролируется и регистрируется
                  поз.PJR-004/01, PJRCA<sup>H</sup>-104/01 сигнализация
                  срабатывает при 10кПа, регулируется клапаном – регулятором
                  01PV-104.
                </p>
                <p>
                  <strong>Вакуумсоздающая аппаратура</strong>
                </p>
                <p>
                  <strong></strong>Пары с верха вакуумной колонны К-01 поступают
                  в межтрубное пространство предварительного конденсатора Е-40А,
                  где частично конденсируются и далее поступают в конденсатор
                  Е-40. Не сконденсировавшиеся пары отсасываются паровыми
                  эжекторами J-01/1,2 и поступают в межтрубное пространство
                  промежуточных конденсаторов Е-41 и Е-41А.
                </p>
                <p>
                  Газы разложения из Е-41 и Е-41А откачиваются водокольцевым
                  насосом Р-15/1,2, поступают в делительную емкость Д-08. Из
                  Д-08 газы разложения через Д-09 и Д-07 подаются на сжигание в
                  печь. Сконденсировавшиеся продукты (паровой конденсат,
                  вакуумный газойль), из Е-40, Е-40А, Е-41, Е-41А и гидрозатвора
                  Д-09 поступают в барометрическую емкость Д-10. В Д-10
                  происходит разделение легкого компонента дизельного топлива от
                  воды за счет разности удельных весов. Загрязненный конденсат
                  из Д-10 откачивается насосом Р-17/1,2 с установки на очистные
                  сооружения совместно со стоками ЭЛОУ установок АВТ-2,6. Легкий
                  компонент диз.топлива из Д-10 откачивается насосом Р-16/1,2 с
                  установки на установки гидроочистки совместно с вакуумным
                  газойлем установки АВТ-2.
                </p>
                <p>
                  Для охлаждения и конденсации паров в трубное пространство
                  Е-40, Е-40А, Е-41, Е-41А, Е-42 подается оборотная вода
                  Iсистемы.
                </p>
                <p>
                  <strong></strong>Эжектора J-01/1,2 могут быть включены в
                  работу последовательно, параллельно или независимо друг от
                  друга. Пар на эжектора J-01/1,2 используется или с заводской
                  системы, или с системы выработки пара высокого давления в
                  D-01.
                </p>
                <p>
                  Температура пара на эжектора контролируется и регистрируется
                  поз.TJR-076/01, расход пара на эжектора контролируется и
                  регистрируется поз.FJR-031/01.
                </p>
                <p>
                  Температура конденсата из Е-40 и Е-41 контролируется
                  поз.TJR-112/01 и TJR-113/01 соответственно. Температура
                  конденсата из Е-40А и Е-41А контролируется поз.TJR-190/01 и
                  TJR-191/01 соответственно.
                </p>
                <table width="617">
                  <tbody>
                    <tr>
                      <td width="167">
                        <p>Технологическая норма</p>
                      </td>
                      <td width="143">
                        <p>Возможные причины отклонения параметра</p>
                      </td>
                      <td width="307">
                        <p>Действия персонала по стабилизации процесса</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="617">
                  <thead>
                    <tr>
                      <td width="167">
                        <p>1</p>
                      </td>
                      <td width="143">
                        <p>2</p>
                      </td>
                      <td width="307">
                        <p>3</p>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="3" width="617">
                        <p>
                          <strong>
                            Ухудшение качества получаемых вакуумных дистиллятов
                          </strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="3" width="167">
                        <p>Норма фракционного состава:</p>
                        <p>ВД-1</p>
                        <p>Температура выкипания 5&nbsp;%, °С, не менее 360</p>
                        <p>Температура выкипания 95&nbsp;%, °С, не более 420</p>
                        <p>ВД-2</p>
                        <p>Температура выкипания 5&nbsp;%, °С, не менее 390</p>
                        <p>Температура выкипания 95&nbsp;%, °С, не более 470</p>
                        <p>ВД-3</p>
                        <p>Температура выкипания 5&nbsp;%, °С, не менее 430</p>
                        <p>Температура выкипания 95&nbsp;%, °С, не более 510</p>
                        <p>ВД-4</p>
                        <p>Температура выкипания 5&nbsp;%, °С, не менее 470</p>
                        <p>Температура выкипания 95&nbsp;%, °С, не более 560</p>
                      </td>
                      <td width="143">
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>
                          Малое (большое) количество пара в колонну К-03/1…4
                        </p>
                        <p>&nbsp;</p>
                      </td>
                      <td width="307">
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>
                          Увеличить (уменьшить) количество пара в колонну
                          К03/1…4
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="143">
                        <p>&nbsp;</p>
                        <p>
                          Низкая (высокая) температура на перетоке из К01 в
                          К-03/1,3
                        </p>
                        <p>&nbsp;</p>
                      </td>
                      <td width="307">
                        <p>&nbsp;</p>
                        <p>
                          Уменьшить (увеличить) расход соответствующего
                          орошения, путем за­крытия (открытия) соответствующего
                          калапана-регулятора.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="143">
                        <p>Понижение вакуума в колонне К-01</p>
                        <p>&nbsp;</p>
                      </td>
                      <td width="307">
                        <p>
                          Проверить: расход, температуру, давление воды I
                          системы на уста­новку; давление пара на эжекторах;
                          состояние уровней в емкости Д-10; герметичность
                          фланцевых соединений соответствующих трубопроводов и
                          аппаратов; работоспособность эжекторов и насосов
                          Р-15/1,2,
                        </p>
                        <p>Р-18/1,2.</p>
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="2" width="167">
                        <p>Норма вязкости кинематической при 100&nbsp;°С, с:</p>
                        <p>ВД-1 не менее 3,5</p>
                        <p>ВД-2 не менее 5,5</p>
                        <p>ВД-3 не менее 10</p>
                        <p>ВД-4 не менее 17</p>
                      </td>
                      <td width="143">
                        <p>
                          Малый расход выводимого&nbsp; вакуумного дистиллята
                        </p>
                        <p>&nbsp;</p>
                      </td>
                      <td width="307">
                        <p>Проверить правильность собранной схемы</p>
                        <p>Увеличить расход вакуумного дистиллята</p>
                      </td>
                    </tr>
                    <tr>
                      <td width="143">
                        <p>
                          Низкая (высокая) температура на перетоке из К-01 в
                          К-03/1,3
                        </p>
                        <p>&nbsp;</p>
                      </td>
                      <td width="307">
                        <p>
                          Уменьшить (увеличить) расход соответствующего
                          орошения, путем закрытия (открытия) соответствующего
                          калапана-регулятора
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="167">
                        <p>Норма цвета:</p>
                        <p>ВД-1 не более 2,0</p>
                        <p>ВД-2 не более 3,0</p>
                        <p>ВД-3 не более 4,5</p>
                        <p>ВД-4 не более 6,5</p>
                      </td>
                      <td width="143">
                        <p>Пропуск&nbsp; теплообменника</p>
                      </td>
                      <td width="307">
                        <p>Проверить на пропуск теплообменники:</p>
                        <p>Е-03</p>
                        <p>Е-04</p>
                        <p>Е-14</p>
                        <p>Е-15</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" width="617">
                        <p>
                          <strong>Ухудшение качества гудрона</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="2" width="167">
                        <p>Норма вязкости условной при 80&nbsp;°С,</p>
                        <p>не менее 25&nbsp;с.</p>
                      </td>
                      <td width="143">
                        <p>Пропуск теплообменника</p>
                      </td>
                      <td width="307">
                        <p>
                          Проверить на пропуск теплообменники: Е-19/1,2;
                          Е-17/1,2; Е-12/1,2;
                        </p>
                        <p>
                          Е-09/2; Е-09/1; Е-07/1,2; Е-06/1,2. Выявлен­ный
                          дефектный теплообменник отключить от технологической
                          схемы для ремонта.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="143">
                        <p>Недостаточный расход верхнего гудрона от насосов</p>
                        <p>Р-07/1,2</p>
                      </td>
                      <td width="307">
                        <p>
                          Открыть больше клапан регулятор 01FV019 верхний гудрон
                          с установки
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" width="617">
                        <p>
                          <strong>
                            Ухудшение качества компонента диз.топлива
                          </strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="2" width="167">
                        <p>Норма фракционного состава:</p>
                        <p>
                          Температура выкипания 5&nbsp;%, °С, не более
                          340&nbsp;°С
                        </p>
                        <p>
                          Температура выкипания 95&nbsp;%, °С, не более
                          380&nbsp;°С
                        </p>
                      </td>
                      <td width="143">
                        <p>Высокая температура верха колонны К-01</p>
                      </td>
                      <td width="307">
                        <p>
                          Включить дополнительный аппарат воздушного охлаждения
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="143">
                        <p>
                          Недостаточный расход верхнего циркуляционного орошения
                          на 2-й пакет.
                        </p>
                      </td>
                      <td width="307">
                        <p>Открыть больше клапан регулятор 01FV011</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" width="617">
                        <p>
                          <strong>
                            Появление нефтепродукта в питательной воде из Д-01,
                            Д-02.
                          </strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="167">
                        <p>
                          Норма содержания нефтепродукта в воде, мг/м
                          <sup>3</sup>, не более 5
                        </p>
                      </td>
                      <td width="143">
                        <p>Пропуск&nbsp; парогенератора</p>
                      </td>
                      <td width="307">
                        <p>
                          Проверить на пропуск парогенераторы: Е-22, Е-23, Е-24,
                          Е-25, Е-26, Е-27. Выявленный дефектный парогенератор
                          отключить от технологической схемы для ремонта.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" width="617">
                        <p>
                          <strong>
                            Загрязнение нефтепродуктами системы горячей воды
                          </strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="167">
                        <p>
                          Норма содержания нефтепродукта в воде, мг/л&nbsp; не
                          более 25
                        </p>
                      </td>
                      <td width="143">
                        <p>Пропуск теплообменника</p>
                      </td>
                      <td width="307">
                        <p>
                          Проверить на пропуск теплообменники:Е-33/1,2…Е-38/1…4.
                          Выявлен­ный дефектный теплообменник отключить от
                          технологической схемы для ремонта.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="617">
                  <thead>
                    <tr>
                      <td width="167">
                        <p>1</p>
                      </td>
                      <td width="143">
                        <p>2</p>
                      </td>
                      <td width="307">
                        <p>3</p>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="167">
                        <p>Норма цвета:</p>
                        <p>ВД-1 не более 2,0</p>
                        <p>ВД-2 не более 3,0</p>
                        <p>ВД-3 не более 4,5</p>
                        <p>ВД-4 не более 6,5</p>
                      </td>
                      <td width="143">
                        <p>Пропуск&nbsp; теплообменника</p>
                      </td>
                      <td width="307">
                        <p>Проверить на пропуск теплообменники:</p>
                        <p>Е-03</p>
                        <p>Е-04</p>
                        <p>Е-14</p>
                        <p>Е-15</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" width="617">
                        <p>
                          <strong>Ухудшение качества гудрона</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="2" width="167">
                        <p>Норма вязкости условной при 80&nbsp;°С,</p>
                        <p>не менее 25&nbsp;с.</p>
                      </td>
                      <td width="143">
                        <p>Пропуск теплообменника</p>
                      </td>
                      <td width="307">
                        <p>
                          Проверить на пропуск теплообменники: Е-19/1,2;
                          Е-17/1,2; Е-12/1,2;
                        </p>
                        <p>
                          Е-09/2; Е-09/1; Е-07/1,2; Е-06/1,2. Выявлен­ный
                          дефектный теплообменник отключить от технологической
                          схемы для ремонта.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="143">
                        <p>Недостаточный расход верхнего гудрона от насосов</p>
                        <p>Р-07/1,2</p>
                      </td>
                      <td width="307">
                        <p>
                          Открыть больше клапан регулятор 01FV019 верхний гудрон
                          с установки
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" width="617">
                        <p>
                          <strong>
                            Ухудшение качества компонента диз.топлива
                          </strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="2" width="167">
                        <p>Норма фракционного состава:</p>
                        <p>
                          Температура выкипания 5&nbsp;%, °С, не более
                          340&nbsp;°С
                        </p>
                        <p>
                          Температура выкипания 95&nbsp;%, °С, не более
                          380&nbsp;°С
                        </p>
                      </td>
                      <td width="143">
                        <p>Высокая температура верха колонны К-01</p>
                      </td>
                      <td width="307">
                        <p>
                          Включить дополнительный аппарат воздушного охлаждения
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="143">
                        <p>
                          Недостаточный расход верхнего циркуляционного орошения
                          на 2-й пакет.
                        </p>
                      </td>
                      <td width="307">
                        <p>Открыть больше клапан регулятор 01FV011</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" width="617">
                        <p>
                          <strong>
                            Появление нефтепродукта в питательной воде из Д-01,
                            Д-02.
                          </strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="167">
                        <p>
                          Норма содержания нефтепродукта в воде, мг/м
                          <sup>3</sup>, не более 5
                        </p>
                      </td>
                      <td width="143">
                        <p>Пропуск&nbsp; парогенератора</p>
                      </td>
                      <td width="307">
                        <p>
                          Проверить на пропуск парогенераторы: Е-22, Е-23, Е-24,
                          Е-25, Е-26, Е-27. Выявленный дефектный парогенератор
                          отключить от технологической схемы для ремонта.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" width="617">
                        <p>
                          <strong>
                            Загрязнение нефтепродуктами системы горячей воды
                          </strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="167">
                        <p>
                          Норма содержания нефтепродукта в воде, мг/л&nbsp; не
                          более 25
                        </p>
                      </td>
                      <td width="143">
                        <p>Пропуск теплообменника</p>
                      </td>
                      <td width="307">
                        <p>
                          Проверить на пропуск теплообменники:Е-33/1,2…Е-38/1…4.
                          Выявлен­ный дефектный теплообменник отключить от
                          технологической схемы для ремонта.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: "#52527a;", color: "white" }}>
                Компьютерная модель установки
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <div className="vt1__modal">
                <ModalComponent
                  name="Компьютерная модель установки"
                  href="https://drive.google.com/file/d/1PjKfQtuAwsFi6BvVM3_pt1zPbIOeBacJ/preview"
                />
              </div>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: "#52527a;", color: "white" }}>
                Схема технологического процесса
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <div className="vt1__modal">
                <ModalComponent
                  name="Схема технологического процесса"
                  href="https://drive.google.com/file/d/1o7gG8ex8Xo9rCDfZoMj53MTo3xtv0rL6/preview"
                />
              </div>
            </AccordionPanel>
          </AccordionItem>
          {/* <AccordionItem>
            <h2>
              <AccordionButton _expanded={{ bg: "#52527a;", color: "white" }}>
                Тест
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            <Test />

            </AccordionPanel>


          </AccordionItem> */}
        </Accordion>
      </div>
      <HydrotreatingSwiper />
    </div>
  );
}

export default vt1;
